<template>
  <div>
    <CSpinner v-if="loading" color="info"/>
    <CAlert
        v-if="error"
        color="danger"
        closeButton
        @update:show="removeError">
      {{error}}
    </CAlert>
    <CNavbar
        type="light"
        class="navbar-partner"
    >
      <CNavItem
          :to="`/partners/vendor/${this.$route.params.vendor_nid}`"
          class="nav-item-partner"
      >
        Vendor
      </CNavItem>
      <CNavItem
          :to="`/partners/vendor/${this.$route.params.vendor_nid}/sales-regions`"
          class="nav-item-partner"
      >
        Sales Regions
      </CNavItem>
      <CNavItem
          :to="`/partners/vendor/${this.$route.params.vendor_nid}/sales-offices`"
          class="nav-item-partner"
      >
        Sales Offices
      </CNavItem>
    </CNavbar>
    <CRow class="add-button-row">
      <CButton
          variant="outline"
          color="info"
          @click="openAddModal">
        Add Sales Office
      </CButton>
    </CRow>
    <SalesOfficeAdd
        v-on:add-item="addItemLocal"
    />
    <CCardBody>
      <CDataTable
        :items="items"
        :fields="fields"
        table-filter
        items-per-page-select
        :items-per-page="itemsPerPage"
        v-on:update:sorter-value="updateOrder"
        v-on:update:table-filter-value="updateFilter"
        v-on:pagination-change="updatePagination"
        hover
        sorter
        :pagination="false"
      >
        <template #edit_item="{item}">
          <td class="py-2">
            <TableButtonGroup
                v-on:view="viewItem(item)"
                v-on:edit="editItem(item)"
                v-on:delete-prompt="toggleDeleteItemModal(item)"
            />
            <DeleteModal
                entity="sales-office"
                :name="deleteItemName"
                :nid="deleteItemID"
                :show.sync="showDeleteItemModal"
                v-on:delete="deleteItem(item)"
                v-on:cancel-delete="toggleDeleteItemModal(item)"
            />
          </td>
        </template>
        <template #activePapp="{item}">
          <td>
            <CIcon v-if="item.activePapp" name="cil-check-circle"/>
            <CIcon v-if="!item.activePapp" name="cil-x-circle"/>
          </td>
        </template>
      </CDataTable>
      <CPagination
          :activePage.sync="activePage"
          :pages="pages"
      />
    </CCardBody>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import DeleteModal from "@/components/common/DeleteModal";
import TableButtonGroup from "@/components/common/TableButtonGroup";
import SalesOfficeAdd from "@/apps/partners/components/SalesOfficeAdd";

const querySalesOffices = gql`
query salesOffices($vendor: NeuraumRef, $offset: Int, $limit: Int, $order: String, $q: String) {
  salesOffices(vendor: $vendor, offset: $offset, limit: $limit, order: $order, q: $q) {
    totalCount
    data {
      nid
      name
      salesRegion {
        name
      }
      activePapp,
      displayRegions {
        postalCode
      }
      handoverRegions {
        postalCode
      }
      salesAgents {
        nid
      }
      handoverMethod
      plan
      updatedAt
    }
  }
}
`
const deleteSalesOffice = gql`
  mutation deleteSalesOffice($nid: ID!){
    deleteSalesOffice(nid: $nid)
  }
`

const fields = [
  {
    key: 'edit_item',
    label: '',
    _style: 'width:1%',
    sorter: false,
    filter: false
  },
  { key: 'name', _style:'min-width:200px' },
  { key: 'salesRegion', label: 'Sales Region', _style:'min-width:200px' },
  { key: 'plan', label: 'Plan', _style:'min-width:50px'},
  { key: 'displayRegions', label: '# Display Regions', _style:'min-width:50px'},
  { key: 'handoverRegions', label: '# Handover Regions', _style:'min-width:50px'},
  { key: 'salesAgents', label: '# Sales Agents', _style:'min-width:50px'},
  { key: 'handoverMethod', label: 'Handover Method', _style:'min-width:50px'},
  { key: 'updatedAt', label: 'Last Updated', _style:'min-width:50px'},
  { key: 'activePapp', label:'Active', _style: 'width:1%', filter: true},
  { key: 'nid', label: 'NID', _style:'min-width:50px'},
]

export default {
  name: "SalesOffices",
  components: {
    SalesOfficeAdd,
    DeleteModal,
    TableButtonGroup
  },
  data() {
    return {
      waiting: false,
      salesOffices: {},
      orderColumn: "updatedAt",
      orderPrefix: "-",
      tableFilter: null,
      itemsPerPage: 20,
      queryOffset: 0,
      activePage: 1,
      error: null,
      fields: fields,
      showDeleteItemModal: false,
      deleteItemID: null,
      deleteItemName: null
    }
  },
  methods: {
    updateOrder(event){
      if(event.column==='salesRegion'){
        this.orderColumn = 'salesRegion.name';
      } else {
        this.orderColumn = event.column;
      }
      this.orderPrefix = event.asc ? '': '-';
    },
    updateFilter(event){
      this.tableFilter = event;
    },
    updatePagination(event){
      this.itemsPerPage = event;
    },
    openAddModal(){
      this.$store.commit('set', ['allowEdit', true]);
      this.$store.commit('set', ['addModalShow', true]);
    },
    getItemName(item){
      return item.name
    },
    editItem(item){
      this.$store.commit('set', ['allowEdit', true]);
      this.$router.push({ path: `/partners/sales-office/${item.nid}`});
    },
    viewItem(item){
      this.$store.commit('set', ['allowEdit', false]);
      this.$router.push({ path: `/partners/sales-office/${item.nid}`});
    },
    toggleDeleteItemModal(item){
      this.deleteItemID = item.nid;
      this.deleteItemName = this.getItemName(item);
      this.showDeleteItemModal = !this.showDeleteItemModal
    },
    deleteItem(item){
      this.$apollo.mutate({
        mutation: deleteSalesOffice,
        variables: {
          nid: this.deleteItemID
        },
        error(error){
          console.error(error);
          this.error = error.message;
          this.deleteItemID = null;
          this.deleteItemName = null;
          this.showDeleteItemModal = false;
        }
      })
      .then(res => {
        this.waiting = true;
        setTimeout(()=> {
          this.$apollo.queries.salesOffices.refetch(() => {
            return {
              offset: this.offset,
              limit: this.itemsPerPage,
              order: this.sortOrder,
              q: this.tableFilter
            }
          })}, 500);
        this.waiting = false;
        this.deleteItemID = null;
        this.deleteItemName = null;
        this.showDeleteItemModal = false;
      })
    },
    updateItemLocal(data){
      this.salesOffices.data = this.salesOffices.data.filter(function(item) {return item.nid === data.nid ? data : item });
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    },
    addItemLocal(item){
      if(this.salesOffices.data.length >= this.itemsPerPage){
        this.salesOffices.data.pop();
      }
      this.salesOffices.data = [item].concat(this.salesOffices.data);
    },
    fetchData(){
      if(this.doRefetch){
        this.queryOffset = 0
        this.tableFilter = null
        this.orderPrefix = '-'
        this.orderColumn = 'updatedAt'
        this.waiting = true
        setTimeout(()=> {
          this.$apollo.queries.salesOffices.refetch(() => {
            return {
              offset: this.offset,
              limit: this.itemsPerPage,
              order: this.sortOrder,
              q: this.tableFilter
          }});
        }, 500);
        this.waiting = false;
        this.$store.commit('set', ['refetchData', false]);
      }
    }
  },
  apollo: {
    salesOffices: {
      query: querySalesOffices,
      variables(){
        return {
          vendor: {nid: this.$route.params.vendor_nid},
          offset: this.offset,
          limit: this.itemsPerPage,
          order: this.sortOrder,
          q: this.tableFilter
        }
      },
      error(error) {
        console.error(error);
        this.error = error.message;
      },
      pollInterval: 10000,
      fetchPolicy: "cache-and-network"
    }
  },
  computed:{
    doRefetch(){
      return this.$store.state.refetchData
    },
    loading(){
      return this.$apollo.queries.salesOffices.loading || this.waiting
    },
    sortOrder: {
      get() {
        return `${this.orderPrefix}${this.orderColumn}`
      },
      set(){

      }
    },
    pages:{
      get() {
        return Math.ceil(this.salesOffices.totalCount / this.itemsPerPage)
      },
      set(){

      }
    },
    offset:{
      get() {
        return this.itemsPerPage*(this.activePage-1)
      },
      set(){

      }
    },
    items () {
      if(this.salesOffices.data){
        return this.salesOffices.data.map(item => {
          return {
            ...item,
            salesRegion: item.salesRegion.name,
            displayRegions: item.displayRegions ? item.displayRegions.length : 0,
            handoverRegions: item.handoverRegions ? item.handoverRegions.length : 0,
            salesAgents: item.salesAgents ? item.salesAgents.length : 0,
            updatedAt: item.updatedAt ? item.updatedAt.substring(0, 10) : null,
          }
        })
      } else {
        return []
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.fetchData())
  },
}
</script>

<style scoped>
  .add-button-row{
    justify-content: start;
    margin-left: 1.25rem;
  }
  .navbar-partner{
    align-items: start;
    align-content: start;
    justify-content: start;
    padding-left: 0px;
    margin-left: 0px;
  }
  .nav-item-partner{
    list-style: none;
  }
</style>